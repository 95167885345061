html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
}

div#auth-container {
  position: absolute;
  z-index: 1;
  display: flex;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
}

div#loading-message {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

div#buttons {
  display: flex;
  flex-direction: column;
}

#root {
  display: flex;
  flex-direction: column;
}

#canvas-container {
  flex-grow: 1;
}

header {
  display: flex;
}

#ping {
  position: absolute;
  z-index: 1;
}
